.p-datatable-wrapper {
  margin-top: 10px;
}
.dropdown-all-width {
  width: auto;
  margin-top: 5px;
}
.ui-column-date {
  width: 200px;
}
.ui-column-version {
  width: 120px;
}
.ui-column-code {
  width: 100px;
}
.ui-column-exception {
  width: 95px;
}
.p-col-6 .p-inputgroup {
  display: initial;
}

