.card-user .input{
  margin-top: 10px;
}

.card-user .input-full-row{
  margin-top: 10px;
}

.card-user .input-small-row{
  margin-top: 10px;
}

.card-user .input-medium-row{
  margin-top: 10px;
}

.card-user .input-diecut-row{
  margin-top: 10px;
}

#cliche{
  margin-top: 16px;
}

.wrap-button {
  padding: 0 0 0 10px;
}

.wrap-btn-sent {
  margin-top: 10px;
  display: flex;
  justify-content: end;
}

@media screen and (min-width: 992px) {
  .card-user .input{
    width: 100%;
  }
  .card-user .input-full-row{
    width: 100%;
  }
  .card-user .input-small-row{
    width: 23.8%;
  }
  .card-user .input-medium-row{
    width: 36.5%;
  }
  .card-user .input-diecut-row{
    width: 28%;
  }
}

div#tooltip:hover:before {
  content: attr(data-tooltip);
  position: absolute;
  padding: 5px 10px;
  margin: -35px 0 0 0px;
  background: rgb(49, 48, 48);
  color: white;
  border-radius: 3px;
}
