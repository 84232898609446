.card-user .input{
  margin-top: 10px;
}

.card-user .input-text{
  margin-top: 10px;
}

.card-user .input-full-row{
  margin-top: 10px;
}

.card-user .input-client{
  margin-top: 10px;
}

.card-user .input-small-row{
  margin-top: 10px;
}

.card-user .input-medium-row{
  margin-top: 10px;
}

.card-user .input-color-row{
  margin-top: 10px;
}

#cliche{
  margin-top: 16px;
}

@media screen and (min-width: 992px) {
  .card-user .input{
    width: 100%;
  }

  .card-user .input-client{
    width: 60%;
  }
  .card-user .input-text{
    width: 95%;
  }
  .card-user .input-full-row{
    width: 90%;
  }
  .card-user .input-small-row{
    width: 23.8%;
  }
  .card-user .input-medium-row{
    width: 36.5%;
  }
  .card-user .input-color-row{
    height: 55%;
    width: 60%;
  }
}
.card-user .card .p-grid .p-growl button{
  background-color: #ffe399;
}


body .p-radiobutton-label{
  margin-right: 10px;
}