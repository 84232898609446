.login-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.logo {
  display: block;
  margin: 0 auto 15px;
}

.login-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100vh;
  background-color: #fff;
}

.login-left  {
  background: url('/assets/images/login-background.jpg') center;
  background-size: cover;
  width: 50%;
  height: 100vh;
  background-color: #ccc;
}

.p-card-body {
  padding: 0;
}

.card-login {
  position: relative;
  z-index: 1;
  padding: 0;
  text-align: center;
  margin: auto;
  box-shadow: 0 0 0 #fff !important;
}
.header-card {
  text-align: left;
  margin-bottom: 20px;
}
.header-card p {
  padding: 0;
  margin: 0;
}
.title-login {
  font-size: 28px;
  font-weight: bolder;
  line-height: 1.2em;
}
.button-link {
  margin-bottom: 10px !important;
}
.btn-submit {
  background: #31332d !important;
  border: 1px solid #000 !important;
  padding: 4px 0;
}
.btn-submit:hover {
  background: #000 !important;
}
.row-input {
  padding: 10px 0;
}
.input-password {
  padding-bottom: 0 !important;
}
.p-float-label {
  padding: 5px 0;
}

.grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 350px;
}
.item-logo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
}
.grid-container img {
  display: block;
  width: auto;
}
.logo-right img {
  margin: 0 auto;
}
.logo-group {
  margin-top: 19px;
}
.logo-colinas {
  margin: 0 auto 10px !important;
}

.footer-login {
  left: 0;
  bottom: 0;
  width: 100%;
  color: #000;
  text-align: center;
  padding: 10px;
}

@media (max-width: 820px) {
  .login-container {
    display: block;
    background: url('/assets/images/login-background.jpg') center fixed;
    background-size: cover;
  }
  .login-left {
    display: none;
  }
  .login-right {
    width: 100%;
    background: none;
    overflow: scroll;
  }
  .wrap-card {
    background: #fff;
    padding: 20px;
    width: 100%;
    max-width: 405px;
  }
}

@media (max-width: 400px)  {
  .grid-container img {
    width: 100%;
  }
}
