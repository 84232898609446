body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.width-all {
  width: 100%;
}

.button-link {
  background: none!important;
  border: none !important;
  padding: 0!important;
  color: #007ad9 !important;
  text-decoration: underline;
  cursor: pointer;
}

.footer-login {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  height: 40px;
  padding: 10px;
}

.all-width {
  width: 100%;
}

.logo {
  display: block;
  margin: auto;
}

.card-login {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 45px;
  text-align: center;
  margin-top: 0px;
}

.image-login {
  background: linear-gradient(0deg,rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)),url('/assets/images/home.jpg');
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
}

@media (min-width: 640px) {
  .card-login {
    margin: auto;
    width: 360px;
    margin-top: 100px;
  }
}
