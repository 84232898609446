.card-login {
  .p-col-20 {
    padding: 12px;
    input {
      width: 100%;
      &:focus{
        box-shadow: 0 0 0 0.1em $hoverButton;
        border-color: $bodyBgColor;
      }
    }
    button {
      width: 100%;
      cursor: pointer;
      background-color: $primaryButton;
      border-color: $primaryButton;
        &:hover {
          background-color: $secundaryButton;
          border-color: $secundaryButton;
          color: $primaryButton;
        }
    }
  }
}