.ui-g-12 {
  .p-inputgroup {
    .p-button {
      margin-left: 5px;
      background-color: $primaryButton;
      border-color: $primaryButton;

      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
    }
    .p-datepicker table td > span.p-highlight {
      background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
    }
    .p-button-primary {
      background-color: $primaryButton;
      border-color: $primaryButton;
      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
    }
    .p-button-warning {
      background-color: $secundaryButton;
      border-color: $secundaryButton;
      &:hover {
        background-color: $primaryButton;
        border-color: $primaryButton;
        color: $bodyBgColor;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
    }
  }
  #div-loader {
    padding: 20px;
    margin-top: 10px;
    justify-content: center;
    display: flex;
  }
  i.pi.pi-spin.pi-spinner {
    font-size: 2em;
  } 
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: #8D918B;
    color: $menuitemActiveBgColor;
    &:focus {
      box-shadow: 0 0 0 0.0em $hoverButton;
    }
  }
  .p-inputgroup-button{
    margin-top: 24px;
    .p-button {
      margin-left: 5px;
      background-color: $primaryButton;
      border-color: $primaryButton;

      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
    }
  }
  .ui-column-email {
    width: 250px;
    overflow-x: hidden;
    min-width: 350px;
  }
  .ui-column-state {
    overflow-x: hidden;
    width: 85px;
  }
  .ui-column {
    overflow-x: hidden;
    min-width: 100px;
  }

  .ui-column-files {
    button {
      background-color: $primaryButton;
      border-color: $primaryButton;
      height: 30px;
      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
    }
  }

  .ui-column-edit {
    width: 80px;
    button {
      background-color: $primaryButton;
      border-color: $primaryButton;
      height: 30px;
      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
    }
  }
}

body {
  .p-datatable .p-sortable-column.p-highlight {
    background-color: #8D918B;
}

  .p-chips > ul.p-inputtext .p-chips-token {
  background:$primaryButton;
}

 .p-disabled, .p-component:disabled {
  opacity: 100;
}

  .p-dialog .p-dialog-footer button {
  background-color: $primaryButton;
  border-color: $primaryButton;
  &:hover {
    background-color: $secundaryButton;
    border-color: $secundaryButton;
    color: $primaryButton;
  }
  &:focus {
    box-shadow: 0 0 0 0.0em $hoverButton;
  }
  
}
}

#attachementsDialog {
  margin-top: auto;
  width: 600px;
  height: 70%;
  overflow: scroll;
  z-index: 1000;
  position: fixed;
  
  .p-dialog-content {
    height: -webkit-fill-available;
  }
}