.card-user .input{
  width: 100%;
}
#totals{
  padding: 20px;
}

#discount{
  margin-bottom: 5px;
}

#datatable{
  table-layout: auto;
} 
.card-user .input{
  margin-top: 10px;
}

.p-inputgroup-addon{
  margin-top: 10px;
}

.card-user .input-client{
  width: 100%;
}

.p-inputgroup {
  display: flex;
}

.custom .p-scrollpanel-wrapper {
  border-right: 9px solid #f4f4f4;
}

div#tooltip:hover:before {
  content: attr(data-tooltip);
  position: absolute;
  padding: 5px 10px;
  margin: -35px 0 0 0px;
  background: rgb(49, 48, 48);
  color: white;
  border-radius: 3px;
}
