/* General */
$fontSize: 14px;
$bodyBgColor: #edf0f5;
$textColor: #333333;
$textSecondaryColor: #707070;
$borderRadius: 3px;
$dividerColor: #e3e3e3;
$transitionDuration: 0.2s;
$maskBgColor: #D3D0CB;
$primaryButton: #31332D;
$secundaryButton: #ffb507;
$hoverButton: #585e61;


/* Menu Common */
$menuitemBadgeBgColor: #8D918B;
$menuitemBadgeColor: #ffffff;
$submenuFontSize: 13px;
$menuitemActiveRouteColor: #272D2D;

/* Menu Light */
$menuBgColorFirst: #f3f4f9;
$menuBgColorLast: #d7dbe8;
$menuitemColor: #8D918B;
$menuitemHoverColor: #ffffff;
$menuitemActiveColor: #ffffff;
$menuitemActiveBgColor: #ffffff;
$menuitemBorderColor: rgba(49,51,49,1);

/* Menu Dark */
$menuDarkBgColorFirst: #31332D;
$menuDarkBgColorLast: #31332D;
$menuitemDarkColor: #ffffff;
$menuitemDarkHoverColor: #ffffff;
$menuitemDarkActiveColor: #ffffff;
$menuitemDarkActiveBgColor: #31332D;
$menuitemDarkBorderColor: rgba(49,51,49,1);

/* Topbar */
$topbarLeftBgColor: #ffb507;
$topbarRightBgColor: #ffb507;
$topbarItemBadgeBgColor: #ef6262;
$topbarItemBadgeColor: #ffffff;
$topbarItemColor: #ffffff;
$topbarItemHoverColor: #293236;
$topbarSearchInputBorderBottomColor: #ffffff;
$topbarSearchInputColor: #ffffff;

/* Footer */
$footerBgColor: #ffffff;
