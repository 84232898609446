.card-user {
  .card {
    .p-radiobutton .p-radiobutton-box.p-highlight{
      background-color: $primaryButton;
      border-color: $primaryButton;
      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
    }
    button {
      background-color: $primaryButton;
      border-color: $primaryButton;
      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
      
    }
    .p-button {
      background-color: $primaryButton;
      border-color: $primaryButton;
      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
      &:focus {
        box-shadow: 0 0 0 0.0em $hoverButton;
      }
      
    }
    .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
      color: $bodyBgColor;
      &:hover {
        background-color: $secundaryButton;
        border-color: $secundaryButton;
        color: $primaryButton;
      }
    }
    .p-checkbox .p-checkbox-box.p-highlight {
      background-color: $primaryButton;
      border-color: $primaryButton;
        &:hover {
          background-color: $secundaryButton;
          border-color: $secundaryButton;
          color: $primaryButton;
        }
        &:focus{
          box-shadow: 0 0 0 0.1em $hoverButton;
          border-color: $bodyBgColor;
        }
    }

    .p-datatable .p-datatable-tbody > tr.p-highlight {
      background-color: $maskBgColor;
      color: #ffffff;
    }
    input{
      &:focus{
        box-shadow: 0 0 0 0.1em $hoverButton;
        border-color: $bodyBgColor;
      }
    }

    .p-dropdown{
      &:focus{
        box-shadow: 0 0 0 0.1em $hoverButton;
        border-color: $bodyBgColor;
      }
    }
    .ui-button-group {
      margin-top: 10px;
    }
  }
}

i.pi.pi-download {
  background-color: $primaryButton;
  color: $bodyBgColor;
  padding: 6px 8px 10px 8px;
  border-radius: 3px;
}
i.pi.pi-download:hover {
  background-color: $secundaryButton;
  color: $primaryButton;
  padding: 6px 8px 10px 8px;
  border-radius: 3px;
}
