.card-user .input{
  margin-top: 10px;
}

.card-user .input-text{
  margin-top: 10px;
}

.card-user .input-full-row{
  margin-top: 10px;
}

.card-user .input-client{
  margin-top: 10px;
}

.card-user .input-small-row{
  margin-top: 10px;
}

.card-user .input-medium-row{
  margin-top: 10px;
}
.card-user .input-phone{
  margin-top: 10px;
}

.card-user .input-color-row{
  margin-top: 10px;
}

#cliche{
  margin-top: 16px;
}

@media screen and (min-width: 992px) {
  .card-user .input{
    width: 100%;
  }

  .card-user .input-client{
    width: 60%;
  }
  .card-user .input-text{
    width: 95%;
  }
  .card-user .input-full-row{
    width: 90%;
  }
  .card-user .input-small-row{
    width: 23.8%;
  }
  .card-user .input-medium-row{
    width: 36.5%;
  }
  .card-user .input-phone{
    width: 39%;
  }
  .card-user .input-color-row{
    height: 55%;
    width: 60%;
  }
}

.p-radiobutton+label {
  margin-top: 15px;
}

div#tooltip:hover:before {
  content: attr(data-tooltip);
  position: absolute;
  padding: 5px 10px;
  margin: -35px 0 0 0px;
  background: rgb(49, 48, 48);
  color: white;
  border-radius: 3px;
}

#chargeRadioButton {
  margin-top: 15px;
}

body .p-radiobutton-label{
  margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #000000;
  background-color: #ffffff;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.2em #ffffff;
}