.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* 31332D */
.btn-primary {
  background-color: #689f38 !important;
  border-color: #689f38 !important;
}
.btn-primary:hover {
  background-color: #537c2f !important;
  border-color: #537c2f !important;
  color: #fff !important;
}
.btn-danger {
  background-color: #d32f2f !important;
  border-color: #d32f2f !important;
}
.btn-danger:hover {
  background-color: #9e2727 !important;
  border-color: #9e2727 !important;
  color: #fff !important;
}
.btn-register {
  background-color: #585e61 !important;
  border-color: #585e61 !important;
}
.btn-register:hover {
  background-color: #414648 !important;
  border-color: #414648 !important;
  color: #fff !important;
}
.btn-download {
  background-color: #fff !important;
  border-color: #31332d !important;
  color: #31332d !important;
}
.btn-download:hover {
  background-color: #fff !important;
  border-color: #606060 !important;
  color: #606060 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
